<template lang="pug">
include ../../../../configs/template
div.container-fluid.pa-0.my-3
  div.row
    div.col-12
      form.row
        +select-validation('body.branch_office', 'BranchOfficeList', 'affiliate', 'nameLang', '["required"]').col-12.col-sm-12
        div.col-12.col-sm-12.d-flex
          +select('contentType', 'ListTypesDocument', 'typeDoc', 'nameLang')(
            @input="createSingleDocument"
            item-value="content_type"
          )
          v-btn(icon @click="createSingleDocument(contentType)").ml-4
            v-icon(color="success") mdi-plus
        div(v-if="body.dependencies.length").col-12.col-sm-12
          div(v-for="(record, index) of body.dependencies" :key="index").row
            div.container-fluid
              div.row
                div.col-12.col-sm-12.d-flex.justify-space-between
                  span {{ $t(`model-${record.content_type}`) }}:
                  v-btn(icon @click="clearArrayDocument(index)")
                    v-icon(color="error") mdi-delete-circle-outline
              div(v-if="record.content_type === CONTENT_TYPE.STATEMENT_ETI").row
                +select('body.dependencies[index].document_object.course_id', 'CoursesList', '', 'nameLang')(
                  :label="`${$t('course')} *`"
                  @input="getInstitutionList(index)"
                  @blur="$v.body.dependencies.$each[index].document_object.course_id.$touch()"
                  :error-messages="$valid.validation($v.body.dependencies.$each[index].document_object.course_id, ['required'])"
                ).col-12.col-sm-12.col-md-4
                +select('body.dependencies[index].document_object.city', '["Чорноморськ", "Одеса", "Миколаїв", "Херсон", "Маріуполь", "Ізмаїл"]', '', 'nameLang')(
                  :label="`${$t('city')} *`"
                  @input="getInstitutionList(index)"
                  @blur="$v.body.dependencies.$each[index].document_object.city.$touch()"
                  :error-messages="$valid.validation($v.body.dependencies.$each[index].document_object.city, ['required'])"
                ).col-12.col-sm-12.col-md-4
                +select('body.dependencies[index].document_object.institution_id', 'FilteredInstitutionsList[index] ? FilteredInstitutionsList[index] : []', '', '"institutionName"')(
                  :label="`${$t('nameInstitution')} *`"
                  @blur="$v.body.dependencies.$each[index].document_object.institution_id.$touch()"
                  item-value="ntz.id"
                  :error-messages="$valid.validation($v.body.dependencies.$each[index].document_object.institution_id, ['required'])"
                ).col-12.col-sm-12.col-md-4
              div(v-if="record.content_type === CONTENT_TYPE.STATEMENT_SAILOR_PASSPORT").row
                +select('body.dependencies[index].document_object.port_id', 'portsList', '', 'nameLang')(
                  :label="`${$t('port')} *`"
                  @blur="$v.body.dependencies.$each[index].document_object.port_id.$touch()"
                  :error-messages="$valid.validation($v.body.dependencies.$each[index].document_object.port_id, ['required'])"
                ).col-12.col-sm-12.col-md-6
                +select('body.dependencies[index].document_object.type_receipt', 'validProcessingOptionsList', '', 'nameLang')(
                  :label="`${$t('receiveDoc')} *`"
                  @blur="$v.body.dependencies.$each[index].document_object.type_receipt.$touch()"
                  :error-messages="$valid.validation($v.body.dependencies.$each[index].document_object.type_receipt, ['required'])"
                ).col-12.col-sm-12.col-md-6
              div(v-if="record.content_type === CONTENT_TYPE.STATEMENT_MEDICAL_CERTIFICATE").row
                +select('body.dependencies[index].document_object.position_id', 'medicalPositionsList', '', 'nameLang')(
                  :label="`${$t('position')} *`"
                  @input="getMedicalInstitutions"
                  @blur="$v.body.dependencies.$each[index].document_object.position_id.$touch()"
                  :error-messages="$valid.validation($v.body.dependencies.$each[index].document_object.position_id, ['required'])"
                ).col-12.col-sm-12.col-md-6
                +select('body.dependencies[index].document_object.medical_institution_id', 'listMedicalInstitution', '', '"value"')(
                  :label="`${$t('medicalInstitution')} *`"
                  @blur="$v.body.dependencies.$each[index].document_object.medical_institution_id.$touch()"
                  :error-messages="$valid.validation($v.body.dependencies.$each[index].document_object.medical_institution_id, ['required'])"
                ).col-12.col-sm-12.col-md-6
              div(v-if="record.content_type === CONTENT_TYPE.STATEMENT_ADVANCED_TRAINING").row
                +select('body.dependencies[index].document_object.level_qualification_id', 'qualificationLevelsList', '', 'nameLang')(
                  :label="`${$t('qualification')} *`"
                  @input="(item) => getListInstitution(item, index)"
                  @blur="$v.body.dependencies.$each[index].document_object.level_qualification_id.$touch()"
                  :error-messages="$valid.validation($v.body.dependencies.$each[index].document_object.level_qualification_id, ['required'])"
                ).col-12.col-sm-12.col-md-6
                +select('body.dependencies[index].document_object.educational_institution_id', 'listInstitution[index]', '', 'nameLang')(
                  :label="`${$t('nameInstitution')} *`"
                  @input="getInstitutionList(index)"
                  @blur="$v.body.dependencies.$each[index].document_object.educational_institution_id.$touch()"
                  :error-messages="$valid.validation($v.body.dependencies.$each[index].document_object.educational_institution_id, ['required'])"
                ).col-12.col-sm-12.col-md-6
      div.row
      div.col-12
        v-btn(color="success" @click="saveStatementOnDocument" :loading="isLoading") {{$t('save')}}

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { sailor } from '@/mixins/validationRules'
import { CONTENT_TYPE, SUCCESS_CODE } from '@/configs/constants'
import { clearBody } from '@/mixins/main'

const initBody = () => ({
  branch_office: null,
  is_payed: false,
  dependencies: []
})

export default {
  name: 'DocumentForm',
  data () {
    return {
      body: initBody(),
      isLoadingInstitution: false,
      isLoading: false,
      listMedicalInstitution: [],
      contentType: null,
      listInstitution: [],
      documentTypes: [CONTENT_TYPE.STATEMENT_ETI,
        CONTENT_TYPE.STATEMENT_SAILOR_PASSPORT,
        CONTENT_TYPE.STATEMENT_MEDICAL_CERTIFICATE,
        CONTENT_TYPE.STATEMENT_ADVANCED_TRAINING],
      CONTENT_TYPE
    }
  },
  validations () { return sailor.packet.documentForm(this) },
  computed: {
    ...mapGetters({
      portsList: 'notDisabledPorts',
      validProcessingOptionsList: 'validSailorPassportProcessing',
      qualificationById: 'qualificationById'
    }),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      BranchOfficeList: state => state.directory.affiliate.filter(value => !value.is_disable),
      DocumentTypesList: state => state.directory.allDocumentTypes,
      CoursesList: state => state.directory.courses,
      FilteredInstitutionsList: state => state.directory.filteredETI,
      medicalPositionsList: state => state.directory.positionMedical
    }),
    ListTypesDocument () {
      return this.DocumentTypesList.filter(el => this.documentTypes?.includes(el.content_type))
    },
    qualificationLevelsList () {
      return this.qualificationById(2)
    }
  },
  methods: {
    ...mapActions(['getFilteredETI', 'getNameInstitutions', 'getMedicalInstitutionForPacket', 'createPaketStatement']),
    async createSingleDocument (type) {
      if (!type) return false
      let singleDocument = {
        content_type: type,
        document_object: {
          content_type: type
        }
      }
      switch (type) {
        case CONTENT_TYPE.STATEMENT_ETI:
          ['course_id', 'institution_id', 'city'].forEach(key => (singleDocument.document_object[key] = null))
          break
        case CONTENT_TYPE.STATEMENT_SAILOR_PASSPORT:
          ['port_id', 'type_receipt'].forEach(key => (singleDocument.document_object[key] = null))
          break
        case CONTENT_TYPE.STATEMENT_MEDICAL_CERTIFICATE:
          ['position_id', 'medical_institution_id'].forEach(key => (singleDocument.document_object[key] = null))
          break
        case CONTENT_TYPE.STATEMENT_ADVANCED_TRAINING:
          ['level_qualification_id', 'educational_institution_id'].forEach(key => (singleDocument.document_object[key] = null))
          break
      }
      this.body.dependencies.push(singleDocument)
    },
    /** Clear document record from @singleDocumentsArr */
    clearArrayDocument (index) {
      this.body.dependencies.splice(index, 1)
      if (!this.body.dependencies.length) {
        this.body.content_type = null
      }
    },
    /** Get filtered ETI institution list by course and city */
    async getInstitutionList (index) {
      const searchQueries = {
        course: this.body.dependencies[index].document_object.course_id,
        city: this.body.dependencies[index].document_object.city,
        arrayIndex: index,
        nameLang: this.nameLang
      }
      if (searchQueries.city && searchQueries.course) {
        await this.getFilteredETI(searchQueries)
        this.body.dependencies[index].document_object.institution_id = this.FilteredInstitutionsList[index]?.[0]?.ntz?.id || null
      }
    },
    async getMedicalInstitutions (item) {
      const response = await this.getMedicalInstitutionForPacket(item)
      this.listMedicalInstitution = response
    },
    async getListInstitution (item, index) {
      this.isLoadingInstitution = true
      const response = await this.getNameInstitutions({ qualification: item })
      this.listInstitution[index] = [200, 201, 204].includes(response.code) ? response.data : []
      this.isLoadingInstitution = false
    },
    async saveStatementOnDocument () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      let data = {
        ...this.$route.params,
        body: {
          ...clearBody({ ...this.body }),
          dependencies: [...this.body.dependencies.map(el => {
            delete el.document_object.content_type
            if (el.content_type === CONTENT_TYPE.STATEMENT_ETI) {
              return {
                content_type: el.content_type,
                document_object: {
                  course_id: el.document_object.course_id,
                  institution_id: el.document_object.institution_id }
              }
            }
            return el
          })]
        }
      }
      const response = await this.createPaketStatement(data)
      if (SUCCESS_CODE.includes(response.code)) {
        this.$v.$reset()
        this.body = initBody()
        this.contentType = null
        this.$parent.$parent.isViewAddSlot = false
      }
      this.isLoading = false
    }
  }
}
</script>
